/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* Hidden from visual browsers, but still accessible to screenreaders */
.u-react-spectrum-screenReaderOnly {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: 0 -1px -1px 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;

  &.is-focusable {
    &:active,
    &:focus {
      clip: auto;
      clip-path: none;
      height: auto;
      margin: inherit;
      overflow: visible;
      position: static;
      width: auto;
      white-space: inherit;
    }
  }
}
