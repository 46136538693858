/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

.flex-container {
  /* this is necessary so that the inner margins don't affect anything outside */
  display: flex;
}

.flex {
  display: flex;
}

.flex-gap {
  --gap: 0px;
  --column-gap: var(--gap);
  --row-gap: var(--gap);

  /* apply a negative margin to counteract the margin on each item at the edges */
  margin: calc(var(--row-gap) / -2) calc(var(--column-gap) / -2);

  /* increase the width and height to account for this margin */
  /* Add 1px to fix rounding error in Safari (╯°□°)╯︵ ┻━┻ */
  width: calc(100% + calc(var(--column-gap) + 1px));
  height: calc(100% + var(--row-gap));
}

.flex-gap > * {
  /* apply half of the gap to each side of every item */
  margin: calc(var(--row-gap) / 2) calc(var(--column-gap) / 2);
}
